export const TERMS_OF_PRIVATE_INFORMATION_TITLE = '개인정보 수집 및 이용에 동의합니다';
export const TERMS_OF_PRIVATE_INFORMATION_CONTENTS = `삼성SDS는 개인정보 수집∙이용을 위하여 개인정보보호법 제15조 및 제22조에 따라 귀하의 동의를 받고자 합니다.

1. 개인정보의 수집 및 이용 목적 : 고객 문의에 대한 답변 제공 및 현황관리
2. 수집하는 개인정보의 항목 : 이름, 이메일, 연락처, 직급, 회사, 업종, 회사 규모
3. 수집한 개인정보의 보유ㆍ이용기간 : <b>동의 철회 시까지</b>


귀하는 위와 같은 개인정보 수집ㆍ이용에 동의하지 않으실 수 있습니다.
동의를 하지 않을 경우 문의하기 서비스가 제한될 수 있습니다.




※ 본 개인정보의 수집이용 등에 대한 상세한 내용은 삼성SDS 홈페이지(<a href="https://samsungsds.com" target="_blank">www.samsungsds.com</a>)에 공개된 <개인정보처리방침>을 참조하시기 바랍니다.`;
export const TERMS_OF_TRANSFER_TITLE = '마케팅 활용에 동의합니다.';
export const TERMS_OF_TRANSFER_CONTENTS = `삼성SDS는 개인정보 수집∙이용을 위하여 개인정보보호법 제15조 및 제22조에 따라 귀하의 동의를 받고자 합니다.

1. 개인정보의 수집 및 이용 목적 : 삼성SDS 솔루션 출시, 이벤트 정보, 뉴스레터 형식의 이메일 마케팅 및 경품 발송 활용
2. 수집하는 개인정보의 항목 : 이름, 이메일, 연락처, 회사, 직급, 업종, 회사 규모
3. 수집한 개인정보의 보유ㆍ이용기간 : <b>동의 철회 시까지</b>


귀하는 위와 같은 개인정보 수집ㆍ이용에 동의하지 않으실 수 있습니다.
동의를 하지 않을 경우 SDS닷컴 문의하기 서비스는 이용 가능하나, 삼성SDS 솔루션 및 이벤트 정보 수신이 제한될 수 있습니다.




※ 본 개인정보의 수집이용 등에 대한 상세한 내용은 삼성SDS 홈페이지(<a href="https://samsungsds.com" target="_blank">www.samsungsds.com</a>)에 공개된 <개인정보처리방침>을 참조하시기 바랍니다.`;
