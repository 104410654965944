<template>
  <modal name="info-submit-modal" class="info-submit-modal"
         height="755" width="456" overflow="auto">
    <div class="info-submit-modal-header">
      <img src="~@/assets/images/close-black.svg" class="close"
           @click="$modal.hide('info-submit-modal')"/>
      <div class="header-image"></div>
      <div class="header-contents">
        {{ $t('info_submit.header_content') }}
      </div>
    </div>
    <div class="info-submit-modal-body">
      <div class="form">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <validation-provider mode="eager" :rules="`required|email|checkEmail:${isValidEmail}`"
                               v-slot="{errors}">
            <md-field :class="{'md-invalid': errors[0]}">
              <label>Email</label>
              <md-input v-model="email" placeholder="Email"/>
              <span class="md-error sign-valid-message">*{{ errors[0] }}</span>
            </md-field>
          </validation-provider>
          <validation-provider rules="required|name|min:1|max:20" v-slot="{errors}">
            <md-field :class="{'md-invalid': errors[0]}">
              <label>Name</label>
              <md-input v-model="name" placeholder="Name"></md-input>
              <span class="md-error sign-valid-message">*{{ errors[0] }}</span>
            </md-field>
          </validation-provider>
          <validation-provider mode="eager" :rules="`required|mobile|checkMobile:${isValidMobile}`"
                               v-slot="{errors}">
            <md-field :class="{'md-invalid': errors[0]}">
              <label>Mobile</label>
              <md-input v-model="mobile" :value="mobile" placeholder="Mobile"
                        @keyup="replaceNotNumber"/>
              <span class="md-error sign-valid-message">*{{ errors[0] }}</span>
            </md-field>
          </validation-provider>
          <validation-provider rules="required|name|min:1|max:20" v-slot="{errors}">
            <md-field :class="{'md-invalid': errors[0]}">
              <label>Company Name</label>
              <md-input v-model="company" placeholder="Company Name"></md-input>
              <span class="md-error sign-valid-message">*{{ errors[0] }}</span>
            </md-field>
          </validation-provider>
          <div v-if="!isSurveySRM">
            <validation-provider :rules="{ required: { allowFalse: false } }"
                                 class="checkbox margin-36">
              <div class="block">
                <input type="checkbox" id="isPrivateTerms"
                       v-model="checkAgreement.isPrivateTerms"/>
                <label for="isPrivateTerms">
                  <div class="img"/>
                </label>
                <div class="text">
                  <a @click="$modal.show('privacy')">{{ $t('info_submit.privacy') }}</a>
                </div>
              </div>
            </validation-provider>
            <validation-provider :rules="{ required: { allowFalse: false } }" class="checkbox">
              <div class="block">
                <input type="checkbox" id="transferOversea"
                       v-model="checkAgreement.transferOversea"/>
                <label for="transferOversea">
                  <div class="img"/>
                </label>
                <div class="text">
                  <a @click="$modal.show('transfer')">{{ $t('info_submit.transfer') }}</a>
                </div>
              </div>
            </validation-provider>
          </div>
          <div v-else>
            <validation-provider :rules="{ required: { allowFalse: false } }" class="checkbox">
              <div class="block">
                <input type="checkbox" id="providePolicy"
                       v-model="checkAgreementSRM"/>
                <label for="providePolicy">
                  <div class="img"/>
                  <span class="select-all-text" v-html="textSurveySRM"></span>
                </label>
              </div>
            </validation-provider>
          </div>
          <button class="submit-button" :disabled="invalid" @click.once="submitPersonalInfo">
            {{ $t('info_submit.check_detail') }}
          </button>
        </validation-observer>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SURVEY_ID_SRM, TRIAL_FRONTEND_URL } from '@/common/config';

export default {
  name: 'InfoSubmitModal',
  data() {
    return {
      name: '',
      email: '',
      mobile: '',
      company: '',
      isValidUsername: true,
      isValidEmail: true,
      isValidMobile: true,
      checkAgreement: {
        isPrivateTerms: false,
        transferOversea: false,
      },
      checkAgreementSRM: false,
      isPasswordValid: false,
      checkedList: [],
    };
  },
  computed: {
    ...mapGetters(['trialSurvey']),
    textSurveySRM() {
      let html = '';
      if (this.trialSurvey.id === SURVEY_ID_SRM) {
        if (this.$i18n.locale.includes('en')) {
          html = 'I have read and agree to The <a href="https://www.samsungsds.com/en/etc/contact/agree/legal.html" target="_blank">Terms of Use</a> and <a href="https://www.samsungsds.com/en/etc/contact/agree/foot_event.html" target="_blank">Privacy Policy</a>. </br> * Receive information on Samsung SDS solutions and events.';
        } else {
          html = '<a href="https://www.samsungsds.com/en/etc/contact/agree/legal.html" target="_blank">서비스이용약관</a> 및 <a href="https://www.samsungsds.com/en/etc/contact/agree/foot_event.html" target="_blank">개인정보처리방침</a>에 대해 읽고 동의합니다. *삼성 SDS 솔루션 및 이벤트에 대한 정보를 받습다.';
        }
      }
      return html;
    },
    isSurveySRM() {
      return this.trialSurvey.id === SURVEY_ID_SRM;
    }
  },
  methods: {
    ...mapGetters(['participantToken']),
    ...mapActions(['savePersonalInfo']),
    replaceNotNumber(e) {
      const replacedValue = e.target.value.replace(/[^0-9\\.]+/g, '');
      e.target.value = replacedValue;
    },
    async submitPersonalInfo() {
      const providePrivateInfo = 'NO';
      const personalInfo = {
        name: this.name,
        email: this.email,
        mobile: this.mobile,
        company: this.company,
        surveyId: this.trialSurvey.id,
        providePrivateInfo,
        participantToken: this.participantToken(),
        resultBaseUrl: `${TRIAL_FRONTEND_URL}/result/`,
      };
      const params = await this.savePersonalInfo(personalInfo);
      if (params) {
        this.$modal.hide('info-submit-modal');
        this.$emit('show', {
          name: this.name,
          email: this.email,
          mobile: this.mobile,
          company: this.company,
          surveyId: this.trialSurvey.id,
          participantToken: this.participantToken(),
        });
      }
    },
  },
  watch: {
    checkAgreement: {
      deep: true,
      handler() {
        /* eslint no-unused-vars: "off" */
        let count = 0;
        Object.entries(this.checkAgreement)
          .map(([key, value]) => {
            if (value === true) {
              count += 1;
            }
            return count;
          });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.info-submit-modal {
  overflow: auto;

  .info-submit-modal-header {
    .close {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }

    .header-image {
      margin: 73px auto 24px;
      width: 121px;
      height: 97px;
      background-size: 121px 97px;
      background-image: url('~@/assets/images/img-sally-assessment-level.svg');
    }

    .header-contents {
      font-size: 16px;
      font-weight: 300;
      text-align: center;
      color: #2a324b;
    }
  }

  .info-submit-modal-body {
    margin-top: 25px;
    margin-right: 84px;
    margin-left: 84px;

    .form {
      .checkbox {
        display: block;
        margin-bottom: 13px;

        .block {
          display: flex;
          flex-direction: row;
        }

        .text {
          font-size: $font_size_14;

          a {
            cursor: pointer;
            text-decoration: underline;
            text-underline-position: under;
            color: #4a4a4a;

            &:hover {
              color: $primary_color;
            }
          }

          margin-left: 6px;
        }
      }

      .select-all-text {
        color: #4a4a4a;
      }

      .margin-36 {
        margin-top: 36px;
      }
    }

    &__text {
      color: #4a4a4a;
      margin-left: 27px;
    }
  }

  .submit-button {
    margin-top: 36px;
    margin-bottom: 36px;
    display: flex;
    justify-content: center;
    width: 288px;
    height: 40px;
    padding: 6px 45px;
  }
}
</style>
<style lang="scss">
@import "~@/assets/style/components/md-field.scss";
</style>
