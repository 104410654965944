<template>
  <!-- eslint-disable -->
  <div class="privacy-and-policy">
    <div class="scroll">
      <div class="header">
        <img src="~@/assets/images/sally-logo-full.svg"/>
      </div>
      <div class="title">{{ $t('privacy_policy') }}</div>
      <div class="detail-container">
        <div class="detail">
          <div class="select-btn">
            <div class="btn-name">{{ $t('privacy.version') }}</div>
            <sally-multi-select
              class="select-box"
              track-by="id"
              label="text"
              @select="selectedOption => select(selectedOption)"
              v-model="ownerFilter"
              :options="options"/>
          </div>
          <div v-html="$sanitize(selected, sanitizeOptions)"/>
        </div>
      </div>
      <div class="footer">
        <Copyright/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SallyMultiSelect from '@/components/Common/SallyMultiSelect.vue';
import Copyright from '@/components/Common/Copyright.vue';
import { format } from '@/common/date.util';

export default {
  name: 'privacyandpolicy',
  data() {
    return {
      data: [],
      result: [],
      options: [],
      selected: [],
      ownerFilter: [],
      sanitizeOptions: {
        allowedTags: ['div', 'pre', 'b', 'span', 'ul', 'ol', 'li', 'img', 'br'],
        allowedAttributes: {
          div: ['class', 'style'],
          pre: ['class'],
          b: ['class'],
          span: ['class'],
          ul: ['class'],
          ol: ['class'],
          li: ['class'],
          br: ['class'],
          img: ['src', 'class', 'style', 'size', 'align'],
        },
      },
    };
  },
  components: {
    SallyMultiSelect,
    Copyright,
  },
  created() {
    this.getHtmlContents();
  },
  methods: {
    ...mapActions(['getPrivacyAndPolicy']),
    select(selectedOption) {
      // this.selected = this.data.filter(p => p.version === selectedOption.text)[0].content;
      this.selected = this.data.filter(p => selectedOption.text.includes(p.version))[0].content;
    },
    async getHtmlContents() {
      this.data = await this.getPrivacyAndPolicy();
      this.options = this.data
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .map((d, index) => {
          const extra = (index === 0) ? '(최신 버전)' : '';
          return { id: d.id, text: `${d.version} ${format(d.date)} ${extra}` };
        });
      this.ownerFilter = this.options[0];
      this.selected = this.data[0].content;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";
.privacy-and-policy {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .scroll {
    overflow: auto;

    .header {
      height: 52px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        height: 32px;
      }
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 180px;
      background-color: #f4f4f6;
      font-size: $font_size_36;
      font-weight: 600;
      letter-spacing: normal;
      text-align: left;
      color: #2a324b;

      @media screen and (max-width: $max_width_size_for_mobile) {
        font-size: $font_size_30;
        padding-left: 24px;
        padding-right: 24px;
      }
    }

    .detail-container {
      display: flex;
      justify-content: center;

      .detail {
        display: flex;
        flex-direction: column;
        margin-bottom: 98px;
        max-width: 1200px;
        width: 100%;
        text-align: left;

        .select-btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 96px;

          .btn-name {
            margin-right: 12px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            color: #2a324b;
          }

          .select-box {
            width: 290px;
          }
        }
      }
    }

    .privacy__footer {
      padding-top: 18px;
      width: 100%;
      background-color: #f7f8fa;
      font-size: $font_size_12;
      font-weight: $font_weight_400;
      color: #acacac;

      .footer {
        padding-bottom: 18px;
      }
    }
  }
}

</style>
<style lang="scss">
@import "~@/assets/style/variable";

.privacy-and-policy {
  .scroll {
    .detail-container {
      margin-left: $standard_margin_size;
      margin-right: $standard_margin_size;

      .detail {
        .title2 {
          font-size: $font_size_24;
          font-weight: 600;
          letter-spacing: normal;
          color: #2a324b;
          margin-top: 97px;
          margin-bottom: 19px;
        }

        .title3 {
          font-size: $font_size_18;
          letter-spacing: normal;
          color: #2a324b;
          font-weight: bold;
          margin-top: 19px;
        }

        .title4 {
          font-size: $font_size_18;
          letter-spacing: normal;
          color: #2a324b;
          margin-top: 48px;
        }

        .contents {
          font-size: $font_size_18;
          letter-spacing: normal;
          color: #2a324b;
          line-height: 1.33;
          white-space: pre-wrap;
          margin: 0;
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 31px;
      background-color: #f7f8fa;
      font-size: $font_size_12;
      font-weight: $font_weight_400;
      letter-spacing: normal;
      color: #acacac;
    }
  }
}

.privacy-and-policy__container {
  ul {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 0;
  }

  ol {
    list-style-type: -moz-ethiopic-numeric;
    list-style-position: outside;
    padding-left: 20px;
  }

  line-height: 24px;

  .privacy-policy {
    font-size: $font_size_18;

    & > div {
      margin-top: 48px;
    }

    .privacy-policy__icon {
      display: grid;
      grid-template-columns: auto auto auto;
      column-gap: 66px;
      row-gap: 24px;
      margin-top: 30px;
      margin-bottom: 18px;
      @media screen and (max-width: $max_width_size_for_mobile) {
        grid-template-columns: auto auto;
        column-gap: 18px;
        row-gap: 18px;
      }

      .policy-icon {
        width: 66px;
        height: 66px;
      }

      &__container {
        display: flex;
        column-gap: 12px;

        &__description {
          color: #707070;
          font-size: $font_size_14;
          margin-top: 6px;
        }
      }
    }
  }

  .privacy-policy__list {
    margin-top: 80px;
    font-size: $font_size_18;

    &__title {
      font-size: $font_size_24;
      padding-bottom: 24px;
      font-weight: 600;
      letter-spacing: normal;
      color: #2a324b;
    }

    &__padding-bottom-18 {
      padding-bottom: 18px;
    }
  }
}
</style>
